<template>
  <router-view/>
</template>

<style >
* {
  padding: 0;
}
html {
  background-color: #161616;
  color: #CBE4DE;
  font-family: 'Montserrat', sans-serif;
}
body {
  margin: 0;
}
hr {
  width: 50%;
  margin: 0 auto 0 auto !important;
  border: 1px solid #0E8388;
}
.tag {
    background-color: #161616 !important;
}
@media screen and (min-width: 768px) {
  .full-screen {
  width: 100vw;
  height: 100vh;
}

}
@import url('https://fonts.cdnfonts.com/css/montserrat');
</style>
